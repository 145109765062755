import { IngredientCategory } from 'models/entities/ingredient-category';
import { IngredientItemAnalysis } from 'models/entities/ingredient-item-analysis';
import { OrderedMenuItemDatasetCollection } from 'models/entities/ordered-menu-item';

import { Column } from '../../models/column';

type Data = {
  columns: Column[];
  ingredientCategory: IngredientCategory;
};

type DataToInit = {
  analysis?: IngredientItemAnalysis[];
  columns?: Column[];
};

type DataToLoad = {
  datasets: OrderedMenuItemDatasetCollection;
};

class Model {

  readonly columns: Column[];
  readonly ingredientCategory: IngredientCategory;
  readonly analysis: IngredientItemAnalysis[];

  constructor(data: Data) {
    this.columns = data.columns;
    this.ingredientCategory = data.ingredientCategory;
    this.analysis = [];
  }

  init(data: DataToInit = {}): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  load(data: DataToLoad): this {
    const analysis = this.analysis.map(it => it.load(data.datasets));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, analysis });
  }

}

export { Model };