import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { lightPrimaryColor, primaryTextColor, primaryLineColor, lightAccentColor, accentTextColor, accentLineColor } from 'views/services/color-scheme';

const columnWidth = '280px';

export const Root = styled.div`
  display: flex;
  justify-content: start;
`;

export const ColumnHeader = styled.div`
  width: ${columnWidth};
  padding: 8px 16px;
  box-sizing: border-box;
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 0.8);
  background-color: ${lightPrimaryColor};
  color: ${primaryTextColor};
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -0.5px;
    right: 0;
    bottom: 0;
    border-left: 1px solid ${primaryLineColor};
    pointer-events: none;
  }
  &.sum, &.average {
    background-color: ${lightAccentColor};
    color: ${accentTextColor};
    &::after {
      border-color: ${accentLineColor};
    }
  }
  display: flex;
  align-content: center;
  gap: 8px;
`;

export const When = styled.div`
`;

export const Sales = styled.div`
`;
