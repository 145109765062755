import React, { FC, useState, useEffect } from 'react';

import { SalesAnalysis } from 'models/entities/sales-analysis';
import { DailySalesDatasetCollection } from 'models/entities/daily-sales';
import { Currency } from 'models/value-objects/currency';

import { Column } from '../../models/column';
import { Model } from './index.model';

import { showMoney } from 'views/services/helpers';
import { Root, ColumnHeader, When, Sales } from './index.styled';

interface Props {
  columns: Column[];
  salesAnalysis: SalesAnalysis;
  dailySales?: DailySalesDatasetCollection;
  currency?: Currency;
}

const ColumnHeaders: FC<Props> = ({ columns, salesAnalysis, dailySales, currency }) => {

  const [model, setModel] = useState(new Model({ columns, salesAnalysis, dailySales, currency }));

  function init() {
    if (columns === model.columns && salesAnalysis === model.salesAnalysis) return;
    setModel(model.init({ columns, salesAnalysis }));
  }

  function load() {
    if (!dailySales) return;
    const timer = setTimeout(() => setModel(model => model.load({ dailySales })), 0);
    return () => clearTimeout(timer);
  }

  useEffect(init, [columns, salesAnalysis]);
  useEffect(load, [dailySales]);

  return (
    <Root>
      {model.columns.map((column, i) => (
        <ColumnHeader key={i} className={column.type}>
          <When>{column.name} :</When>
          <Sales>{showMoney(model.salesAnalysis.statistics.map[column.key]?.subtotal, model.currency)}</Sales>
        </ColumnHeader>
      ))}
    </Root>
  );

}

export { ColumnHeaders };
