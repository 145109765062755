import { Period, PeriodDays, PeriodDay } from 'models/value-objects/period';

type ColumnType = 'date' | 'sum' | 'average';

type Data = {
  type: ColumnType;
  week?: PeriodDays;
  day?: PeriodDay;
};

class Column {

  readonly type: ColumnType;
  readonly week?: PeriodDays;
  readonly day?: PeriodDay;
  readonly name: string;
  readonly key: string;

  constructor(data: Data) {
    this.type = data.type;
    this.week = data.week;
    this.day = data.day;
    this.name = this.getName();
    this.key = this.getKey();
  }

  private getName(): string {
    switch (this.type) {
      case 'date': return this.day!.object.format('ddd, MMM D');
      case 'sum': return `Week ${this.week![0].weekOfYear}, Sum`;
      case 'average': return `Week ${this.week![0].weekOfYear}, Average`;
    }
  }

  static collectFrom(period: Period): Column[] {
    let collection: Column[] = [];
    period.weeks.forEach(days => {
      days.forEach(day => {
        collection.push(new Column({ type: 'date', day: day }))
      });
      collection.push(new Column({ type: 'sum', week: days }))
      collection.push(new Column({ type: 'average', week: days }))
    });
    return collection;
  }

  private getKey(): string {
    switch (this.type) {
      case 'date': return this.day!.string;
      case 'sum': return `week-${this.week![0].weekOfYear}-sum`;
      case 'average': return `week-${this.week![0].weekOfYear}-average`;
    }
  }

}

export { Column };
