import { SalesAnalysis } from 'models/entities/sales-analysis';
import { DailySalesDatasetCollection } from 'models/entities/daily-sales';
import { Currency } from 'models/value-objects/currency';

import { Column } from '../../models/column';

type Data = {
  columns: Column[];
  salesAnalysis: SalesAnalysis;
  dailySales?: DailySalesDatasetCollection;
  currency?: Currency;
};

type DataToInit = {
  columns?: Column[];
  salesAnalysis?: SalesAnalysis;
  currency?: Currency;
};

type DataToLoad = {
  dailySales: DailySalesDatasetCollection;
};

class Model {

  readonly columns: Column[];
  readonly salesAnalysis: SalesAnalysis;
  readonly dailySales?: DailySalesDatasetCollection;
  readonly currency?: Currency;

  constructor(data: Data) {
    this.columns = data.columns;
    this.salesAnalysis = data.dailySales ? data.salesAnalysis.load({ dailySalesDatasets: data.dailySales }) : data.salesAnalysis;
    this.dailySales = data.dailySales;
    this.currency = data.currency;
  }

  init(data: DataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  load(data: DataToLoad): this {
    const { dailySales } = data;
    const salesAnalysis = this.salesAnalysis.load({ dailySalesDatasets: dailySales });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, salesAnalysis });
  }

}

export { Model };
